<template>
  <div class="md-layout text-center login-fix-page">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <form @submit.prevent="resetPassword">
        <login-card header-color="apure">
          <h4 slot="title" class="title">Informe suas credenciais</h4>
          <md-field
            class="md-form-group md-invalid"
            style="margin-bottom: 2rem"
            slot="inputs"
          >
            <md-icon>mark_email_read</md-icon>
            <label>Código de confirmação</label>
            <md-input v-model="client_code" /> 
            <validation-error :error="apiValidationErrors.client_code" />
          </md-field>
          <md-field class="form-group md-invalid" slot="inputs">
            <md-icon>lock_outline</md-icon>
            <label>Senha...</label>
            <md-input v-model="password" type="password" />
            <validation-error :error="apiValidationErrors.password" />
          </md-field>
          <md-field class="form-group md-invalid" slot="inputs">
            <md-icon>lock_outline</md-icon>
            <label>Confirme a Senha...</label>
            <md-input v-model="confirmPassword" type="password" />
          </md-field>
          <md-button
            slot="footer"
            type="submit"
            class="md-simple md-success md-lg"
          >
            Alterar senha
          </md-button>
        </login-card>
      </form>
    </div>
  </div>
</template>

<script>
import { LoginCard, ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";
import Swal from "sweetalert2";

export default {
  components: {
    LoginCard,
    ValidationError,
  },

  mixins: [formMixin],
  data() {
    return {
        password: null,
        confirmPassword: null,
        client_code: null
    };
  },

  methods: {
    async resetPassword() {
      const user = {
        confirmation_hash: this.$store.getters.confirmationHash,
        password: this.password,
        confirmation_password: this.confirmPassword,
        client_code: this.client_code
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

      try {
        const this_ori = this;
        Swal.showLoading();
        await this.$store.dispatch("resetPassword", { user, requestOptions });
      } catch (e) {
        await this.$store.dispatch("alerts/error", "Login ou senha errado!");
        this.setApiValidation(e.response.data);
      }finally {
        setTimeout(function(){
          Swal.close();
        }, 2000);
      }
    },
  },
};
</script>
<style scoped>
.login-fix-page {
  padding-bottom: 7em;
  padding-top: 4em;
}
</style>
