<template>
  <div>
    <div class="header text-center">
      <h3 class="title">Operações</h3>
    </div>
    <md-card>
      <md-card-content>
        <div class="md-tabs-navigation md-elevation-0" style="background-color: #00122a">
          <button
            type="button"
            @click="changeTab($event, 'operation')"
            class="md-button md-tab-nav-button md-theme-default md-active"
          >
            <div class="md-ripple">
              <div class="md-button-content">Operações à vista</div>
            </div>
          </button>
          <button
            type="button"
            @click="changeTab($event, 'flexOption')"
            class="md-button md-tab-nav-button md-theme-default"
          >
            <div class="md-ripple">
              <div class="md-button-content">Operações Balcão</div>
            </div>
          </button>
          <button
            type="button"
            @click="changeTab($event, 'futureTrade')"
            class="md-button md-tab-nav-button md-theme-default"
          >
            <div class="md-ripple">
              <div class="md-button-content">Operações Futuros</div>
            </div>
          </button>
          <span
            class="md-tabs-indicator md-tabs-indicator-right"
            style="left: 75px; right: calc(100% - 339px)"
          ></span>
        </div>
        <md-card v-if="selectedTab != 'CalculatedIncomeTax'">
          <md-card-content>
            <div class="md-tab-filter filter-container">

              <!--
              <div class="field-filter">
                <span>Ativo: </span>
                <Autocomplete
                  v-model="stockIds"
                  :apiUrl="'http://localhost:8000/api/v1/stock/'"
                  :authToken="'4fa41d327871fdbd293513d50ca02095c49d4bfc'"
                  @select="onAssetSelect"
                />
              </div>
              -->
              
              <div class="field-filter">
                <span>Ativos:</span>
                <Autocomplete
                  v-model="stockIds"
                  id="stockIds" 
                  name="stockIds"
                  :apiUrl="'/stock/'"
                  :authToken="this.$store.getters.token"
                  @select="onBrokerSelect"
                  @remove="onBrokerRemove"
                />
              </div>

              <div class="field-filter">
                <span>Corretora: </span>
                <md-field>
                  <label for="brokerId">Corretora</label>
                  <md-select v-model="brokerId" id="brokerId" name="brokerId">
                    <md-option v-for="item in listBrokerUser" :key="item.brokerId" :value="item.brokerId" >{{item.name}}</md-option>
                  </md-select>
                </md-field>

              </div>

              <div class="field-filter">
                <span>Operação: </span>

              <md-field>
                <label for="type_operaton">Tipo</label>
                <md-select v-model="type_operaton" id="type_operaton" name="type_operaton">
                  <md-option value="buy">Compra</md-option>
                  <md-option value="sell">Venda</md-option>
                  <md-option value="close">Fechamento</md-option>
                </md-select>
              </md-field>
              </div>  

              <div class="field-filter">
                <span>Data inicial: </span>
                <md-datepicker v-model="initialDate" md-immediately />
              </div>
              <div class="field-filter">
                <span>Data final: </span>
                <md-datepicker v-model="finalDate" md-immediately />
              </div>

              <!--
              <span style="margin-left: 2%"></span>
              <md-switch v-model="onlyClosed">Operações finalizadas</md-switch>
              -->
              <span style="margin-left: 2%"></span>
              <md-switch v-show="selectedTab != 'flexOption'" v-model="onlyDayTrade"
                >Somente Day Trade</md-switch
              >
              <md-button
                v-if="!paginationIsLoading"
                class="md-raised md-warning md-lg"
                @click="initializeTabs"
              >
                <span>Filtrar</span>
              </md-button>
              <md-button v-else class="md-raised md-warning md-lg" disabled>
                <span>Filtrando...</span>
              </md-button>
            </div>
          </md-card-content>
        </md-card>
        <div class="page-container-section">
          <!-- Operações à Vista -->
          <md-table
            v-if="isOperationsLoaded && selectedTab == 'operation'"
            v-model="OperationsPaginated"
          >
            <md-table-empty-state
              md-label="Nenhuma operação encontrada..."
              :md-description="`Nenhuma operação encontrada com os filtros acima.`"
            >
            </md-table-empty-state>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Ativo" data-title="Ativo">
                <span style="font-weight: bold">{{ item.stockId }}</span>
              </md-table-cell>
              <md-table-cell md-label="Corretora" data-title="Corretora">
                <span style="font-weight: bold">{{ item.broker.name }}</span>
              </md-table-cell>
              <md-table-cell md-label="Data" data-title="Data">
                <span style="font-weight: bold">{{ item.dateFormated }}</span>
              </md-table-cell>
              <md-table-cell md-label="Tipo" data-title="Tipo">
                <span v-if="item.corporateEventType" style="font-weight: bold">{{
                  item.corporateEventType
                }}</span>
                <span
                  v-else-if="item.type == 'buy'"
                  style="font-weight: bold; color: #66bb6a"
                  >{{ item.typeFormated }}</span
                >
                <span
                  v-else-if="item.type == 'sell'"
                  style="font-weight: bold; color: #f44336"
                  >{{ item.typeFormated }}</span
                >
                <span v-else style="font-weight: bold">{{ item.typeFormated }}</span>
              </md-table-cell>
              <md-table-cell md-label="Quantidade" data-title="Quantidade" md-numeric>
                <span style="font-weight: bold">{{ item.shares }}</span>
              </md-table-cell>
              <md-table-cell md-label="Preço" data-title="Preço" md-numeric>
                <span style="font-weight: bold">{{ item.price | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Emolumentos" data-title="Emolumentos" md-numeric>
                <span style="font-weight: bold">{{
                  item.totalFee | CurrencyFormat
                }}</span>
              </md-table-cell>
              <md-table-cell md-label="Corretagem" data-title="Corretagem" md-numeric>
                <span style="font-weight: bold">{{
                  item.commission | CurrencyFormat
                }}</span>
              </md-table-cell>
              <md-table-cell md-label="Total" data-title="Total" md-numeric>
                <span style="font-weight: bold">{{ item.total | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Lucro" data-title="Lucro" md-numeric>
                <span v-if="item.profit > 0" style="font-weight: bold; color: #66bb6a">{{
                  item.profit | CurrencyFormat
                }}</span>
                <span
                  v-else-if="item.profit < 0"
                  style="font-weight: bold; color: #f44336"
                  >{{ item.profit | CurrencyFormat }}</span
                >
                <span v-else style="font-weight: bold"> -- </span>
              </md-table-cell>
              <md-table-cell md-label="Lucro %" data-title="Lucro %" md-numeric>
                <span
                  v-if="item.profitPercent > 0"
                  style="font-weight: bold; color: #66bb6a"
                  >{{ item.profitPercent }}%</span
                >
                <span
                  v-else-if="item.profitPercent < 0"
                  style="font-weight: bold; color: #f44336"
                  >{{ item.profitPercent }}%</span
                >
                <span v-else style="font-weight: bold"> -- </span>
              </md-table-cell>
              <!-- <md-table-cell md-label=""
                ><md-icon>more_vert</md-icon></md-table-cell
              > -->
            </md-table-row>
          </md-table>
          <!-- Balcão -->
          <md-table
            v-if="isFlexOptionLoaded && selectedTab == 'flexOption'"
            v-model="flexOptionPaginated"
          >
            <md-table-empty-state
              md-label="Nenhuma operação encontrada..."
              :md-description="`Nenhuma operação encontrada com os filtros acima.`"
            >
            </md-table-empty-state>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Mercado" data-title="Mercado">
                <span style="font-weight: bold">{{
                  item.flexOptionCommodity.description
                }}</span>
              </md-table-cell>
              <md-table-cell md-label="Ativo" data-title="Ativo">
                <span style="font-weight: bold">{{ item.flexOption.flexOptionId }}</span>
              </md-table-cell>
              <md-table-cell md-label="Contrato" data-title="Contrato">
                <span style="font-weight: bold">{{ item.contractNumber }}</span>
              </md-table-cell>
              <md-table-cell md-label="Corretora" data-title="Corretora">
                <span style="font-weight: bold">{{ item.broker.name }}</span>
              </md-table-cell>
              <md-table-cell md-label="Data" data-title="Data">
                <span style="font-weight: bold">{{ item.date | DateFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Tipo" data-title="Tipo">
                <span
                  v-if="item.flexOptionCommodity.operationType == 'buy'"
                  style="font-weight: bold; color: #66bb6a"
                  >Compra</span
                >
                <span
                  v-if="item.flexOptionCommodity.operationType == 'sell'"
                  style="font-weight: bold; color: #f44336"
                  >Venda</span
                >
              </md-table-cell>
              <md-table-cell md-label="Quantidade" data-title="Quantidade" md-numeric>
                <span style="font-weight: bold">{{ item.shares }}</span>
              </md-table-cell>
              <md-table-cell md-label="Preço" data-title="Preço" md-numeric>
                <span style="font-weight: bold">{{ item.price | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Corretagem" data-title="Corretagem" md-numeric>
                <span style="font-weight: bold">{{
                  item.commission | CurrencyFormat
                }}</span>
              </md-table-cell>
              <md-table-cell md-label="Tx. Reg." data-title="Tx. Reg." md-numeric>
                <span style="font-weight: bold">{{
                  item.registrationFee | CurrencyFormat
                }}</span>
              </md-table-cell>
              <md-table-cell md-label="Tx. Emol." data-title="Tx. Emol." md-numeric>
                <span style="font-weight: bold">{{ item.fees | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Custo" data-title="Custo" md-numeric>
                <span style="font-weight: bold">{{ item.cost | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Lucro" data-title="Lucro" md-numeric>
                <span
                  v-if="item.profitLoss > 0"
                  style="font-weight: bold; color: #66bb6a"
                  >{{ item.profitLoss | CurrencyFormat }}</span
                >
                <span
                  v-else-if="item.profitLoss < 0"
                  style="font-weight: bold; color: #f44336"
                  >{{ item.profitLoss | CurrencyFormat }}</span
                >
                <span v-else style="font-weight: bold">{{
                  item.profitLoss | CurrencyFormat
                }}</span>
              </md-table-cell>
              <!-- <md-table-cell md-label=""
                ><md-icon>more_vert</md-icon></md-table-cell
              > -->
            </md-table-row>
          </md-table>
          <!-- Futuros -->
          <md-table
            v-if="isFutureTradeLoaded && selectedTab == 'futureTrade'"
            v-model="futureTradePaginated"
          >
            <md-table-empty-state
              md-label="Nenhuma operação encontrada..."
              :md-description="`Nenhuma operação encontrada com os filtros acima.`"
            >
            </md-table-empty-state>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Ativo" data-title="Ativo">
                <span style="font-weight: bold">{{
                  item.futureContract.futureContractId
                }}</span>
              </md-table-cell>
              <md-table-cell md-label="Corretora" data-title="Corretora">
                <span style="font-weight: bold">{{ item.broker.name }}</span>
              </md-table-cell>
              <md-table-cell md-label="Data" data-title="Data">
                <span style="font-weight: bold">{{ item.date | DateFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Tipo" data-title="Tipo">
                <span
                  v-if="item.type == 'buy'"
                  style="font-weight: bold; color: #66bb6a"
                  >{{ item.typeFormated }}</span
                >
                <span
                  v-else-if="item.type == 'sell'"
                  style="font-weight: bold; color: #f44336"
                  >{{ item.typeFormated }}</span
                >
                <span v-else style="font-weight: bold">{{ item.typeFormated }}</span>
              </md-table-cell>
              <md-table-cell md-label="Quantidade" data-title="Quantidade" md-numeric>
                <span style="font-weight: bold">{{ item.shares }}</span>
              </md-table-cell>
              <md-table-cell md-label="Preço" data-title="Preço" md-numeric>
                <span style="font-weight: bold">{{ item.price | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Vencimento" data-title="Vencimento">
                <span style="font-weight: bold">{{
                  item.futureContract.dueDate | DateFormat
                }}</span>
              </md-table-cell>
              <md-table-cell md-label="Corretagem" data-title="Corretagem" md-numeric>
                <span style="font-weight: bold">{{
                  item.commission | CurrencyFormat
                }}</span>
              </md-table-cell>
              <md-table-cell md-label="Emolumentos" data-title="Emolumentos" md-numeric>
                <span style="font-weight: bold">{{
                  item.tradingFee | CurrencyFormat
                }}</span>
              </md-table-cell>
              <md-table-cell md-label="Total" data-title="Total" md-numeric>
                <span style="font-weight: bold">{{ item.cost | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Lucro" data-title="Lucro" md-numeric>
                <span
                  v-if="item.profitLoss > 0"
                  style="font-weight: bold; color: #66bb6a"
                  >{{ item.profitLoss | CurrencyFormat }}</span
                >
                <span
                  v-else-if="item.profitLoss < 0"
                  style="font-weight: bold; color: #f44336"
                  >{{ item.profitLoss | CurrencyFormat }}</span
                >
                <span v-else style="font-weight: bold">{{
                  item.profitLoss | CurrencyFormat
                }}</span>
              </md-table-cell>
              <!-- <md-table-cell md-label=""
                ><md-icon>more_vert</md-icon></md-table-cell
              > -->
            </md-table-row>
          </md-table>
          <md-button
            v-if="selectedTab == 'operation' && !paginationIsLoading"
            class="md-raised md-success md-lg"
            @click="getOperations"
            >Ver Mais</md-button
          >
          <md-button
            v-if="selectedTab == 'operation' && paginationIsLoading"
            class="md-raised md-success md-lg"
            disabled
            >Carregando...</md-button
          >
          <md-button
            v-if="selectedTab == 'flexOption' && !paginationIsLoading"
            class="md-raised md-success md-lg"
            @click="getFlexOption"
            >Ver Mais</md-button
          >
          <md-button
            v-if="selectedTab == 'flexOption' && paginationIsLoading"
            class="md-raised md-success md-lg"
            disabled
            >Carregando...</md-button
          >
          <md-button
            v-if="selectedTab == 'futureTrade' && !paginationIsLoading"
            class="md-raised md-success md-lg"
            @click="getFutureTrade"
            >Ver Mais</md-button
          >
          <md-button
            v-if="selectedTab == 'futureTrade' && paginationIsLoading"
            class="md-raised md-success md-lg"
            disabled
            >Carregando...</md-button
          >
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import moment from "moment";
import Swal from "sweetalert2";

import Autocomplete from './Autocomplete.vue';
// import AutocompleteMultiple from './AutocompleteMultiple.vue';




export default {
  components: {
    // Registre o componente Autocomplete
    Autocomplete,
  },
  data: () => ({
    assets: [],
    brokers: [],
    stockIds:[],
    type_operaton: '',
    brokerId: '',
    selectedAsset: null,
    selectedBroker: null,
    listBrokerUser: [],


    profile: null,
    params: {},
    OperationsPaginated: [],
    OperationTotal: {
      sum: 0,
      totalGain: 0,
      totalGainPercent: 0,
      totalItems: 0,
    },
    flexOptionPaginated: [],
    flexOptionTotal: {
      sum: 0,
      totalGain: 0,
      totalGainPercent: 0,
      totalItems: 0,
    },
    futureTradePaginated: [],
    futureTradeTotal: {
      sum: 0,
      totalGain: 0,
      totalGainPercent: 0,
      totalItems: 0,
    },
    isOperationsLoaded: false,
    isFlexOptionLoaded: false,
    isFutureTradeLoaded: false,
    paginationIsLoading: true,
    tabsPage: {
      operation: 1,
      flexOption: 1,
      futureTrade: 1,
    },
    defaultPageSize: 100,
    onlyClosed: false,
    onlyDayTrade: false,
    selectedTab: "operation",
    initialDate: null,
    finalDate: null,
  }),
  methods: {
    onAssetSelect(item) {
      console.log('Ativo selecionado:', item);
      // Atualize o estado conforme necessário
    },
    onBrokerSelect(item) {
      // this.brokerId = item.mystcoks_broker_id
      // console.log('Corretora selecionada:', item);
      this.stockIds.push(item.stockId)
      // Atualize o estado conforme necessário
    },
    onBrokerRemove(item) {
      // console.log('Corretora removida:', item);
      this.stockIds = this.stockIds.filter(stockId => stockId !== item)
      // Atualize o estado conforme necessário
    },
    async initializeTabs() {
      if (this.profile == null) {
        this.profile = await this.$store.dispatch("profile/me");
      }

      if (this.initialDate == null && this.finalDate == null) {
        var initialDate = new Date(this.profile.last_brokerage_note);
        initialDate = new Date(initialDate.getFullYear(), initialDate.getMonth(), 1);
        this.initialDate = moment(initialDate).format("DD/MM/YYYY");
        this.finalDate = moment(new Date(this.profile.last_brokerage_note)).format(
          "DD/MM/YYYY"
        );
      }
      
      this.paginationIsLoading = true;
      this.OperationsPaginated = [];
      this.flexOptionPaginated = [];
      this.futureTradePaginated = [];
      for (var key in this.tabsPage) {
        this.tabsPage[key] = 1;
      }
      const  stockIdsmontado = this.stockIds.map(stockId => stockId).join(',')
      this.params = {
        token: this.$store.getters.token,
        page: 1,
        stockIds: stockIdsmontado,
        type: this.type_operaton,
        brokerId: this.brokerId,
        pageSize: this.defaultPageSize,
        initialDate: moment(this.initialDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        finalDate: moment(this.finalDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        onlyClosed: this.onlyClosed,
        onlyDayTrade: this.onlyDayTrade,
      };
      this.getBrokerUser();
      this.getOperations();
      this.getFlexOption();
      this.getFutureTrade();
    },
    changeTab(event, selectedTab) {
      $(".md-active").removeClass("md-active");
      $(event.currentTarget).addClass("md-active");
      this.selectedTab = selectedTab;
    },
    async getBrokerUser () {
        var brokerUsers = null;
        brokerUsers = await this.$store.dispatch("profile/getBrokerUser", this.params);
        console.log("brokerUsers- >", brokerUsers)
        this.listBrokerUser = brokerUsers
        
    },
    async getOperations() {
      var Operations = null;
      this.paginationIsLoading = true;
      this.params["page"] = this.tabsPage["operation"];
      console.log(this.params);
      Operations = await this.$store.dispatch("profile/getOperations", this.params);
      console.log("op- >", Operations)

      // if (Operations == undefined) {
      //   this.paginationIsLoading = false;
      //   return;
      // }

      this.OperationsPaginated = this.OperationsPaginated.concat(Operations.items);
      this.OperationTotal.sum = Operations.sum;
      this.OperationTotal.totalGain = Operations.totalGain;
      this.OperationTotal.totalGainPercent = Operations.totalGainPercent;
      this.OperationTotal.totalItems = Operations.totalItems;
      this.paginationIsLoading = false;
      this.isOperationsLoaded = true;
      this.tabsPage["operation"]++;
      console.log(this.paginationIsLoading)
    },
    async getFlexOption() {
      var flexOption = null;
      this.paginationIsLoading = true;
      this.params["page"] = this.tabsPage["flexOption"];
      flexOption = await this.$store.dispatch("profile/getFlexOption", this.params);
      this.flexOptionPaginated = this.flexOptionPaginated.concat(flexOption.items);
      this.paginationIsLoading = false;
      this.isFlexOptionLoaded = true;
      this.tabsPage["flexOption"]++;
    },
    async getFutureTrade() {
      var futureTrade = null;
      this.paginationIsLoading = true;
      this.params["page"] = this.tabsPage["futureTrade"];
      futureTrade = await this.$store.dispatch("profile/getFutureTrade", this.params);
      this.futureTradePaginated = this.futureTradePaginated.concat(futureTrade.items);
      this.paginationIsLoading = false;
      this.isFutureTradeLoaded = true;
      this.tabsPage["futureTrade"]++;
    },
  },
  beforeMount: function () {
    this.$nextTick(function () {
      this.initializeTabs();
    });
  },
};
</script>
<style lang="scss" scoped>
.field-filter {
  display: flex;
  flex-direction: column;
}

.filter-container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
}
</style>
