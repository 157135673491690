<template>
  <div id="dashboard">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card header-color="apure">
          <template slot="header">
            <div class="card-icon">
              <md-icon>attach_money</md-icon>
            </div>
            <p class="category">Total de Darfs</p>
            <h3 class="title" v-if="profileIsLoaded">
              {{ profile.total_darf }}
            </h3>
            <div v-else class="loading"></div>
          </template>

          <template slot="footer">
            <div class="stats" v-if="profileIsLoaded">
              <md-icon>update</md-icon>
              Última Darf {{ profile.last_paid_darf | MonthYearFormatDate }}
            </div>
            <div v-else class="loading"></div>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card header-color="apure">
          <template slot="header">
            <div class="card-icon">
              <md-icon>equalizer</md-icon>
            </div>
            <p class="category">Meses Apurados</p>
            <h3 class="title" v-if="profileIsLoaded">
              {{ profile.total_calculated_income_tax }}
            </h3>
            <div v-else class="loading"></div>
          </template>

          <template slot="footer">
            <div class="stats" v-if="profileIsLoaded">
              <md-icon>update</md-icon>
              Último mês apurado {{ profile.last_calculated_income_tax | MonthYearFormatDate }}
            </div>
            <div v-else class="loading"></div>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card header-color="apure">
          <template slot="header">
            <div class="card-icon">
              <md-icon>candlestick_chart</md-icon>
            </div>
            <p class="category">Total investido</p>
            <h3 class="title" v-if="profileIsLoaded">
              {{ totalPortfolio | CurrencyFormat }}
            </h3>
            <div v-else class="loading"></div>
          </template>

          <template slot="footer">
            <div class="stats" v-if="portfolioIsLoaded">
              <md-icon>attach_money</md-icon>
              Ganho <span v-if="totalGain < 0" style="color: #f44336">{{ gainPercent | ValueFormat }}% / {{ totalGain | CurrencyFormat }}</span>
              <span v-else style="color: #66bb6a">{{ gainPercent | ValueFormat }}% / {{ totalGain | CurrencyFormat }}</span>
            </div>
            <div v-else class="loading"></div>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card header-color="apure">
          <template slot="header">
            <div class="card-icon">
              <md-icon>data_exploration</md-icon>
            </div>
            <p class="category">Plano atual</p>
            <h3 class="title" v-if="profileIsLoaded && profile.active_plan">{{ profile.active_plan.plan_object.name }}</h3>
            <h3 class="title" v-else-if="!profile.active_plan">Sem plano ativo.</h3>
            <div v-else class="loading"></div>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon class="text-danger">warning</md-icon>
              <a>Gerenciar assinatura</a>
            </div>
          </template>
        </stats-card>
      </div>

      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100" style="display: contents">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50">
          <stats-card header-color="apure">
            <template slot="header">
              <div style="text-align: left; font-size: x-large; font-weight: bold; margin-top: 2%">Imposto</div>
              <div style="text-align: left; font-family: arial">
                Verifique, emita ou imprima o DARF de {{ profile.last_calculated_income_tax | MonthYearFormatDateWithName }}
              </div>

              <div class="container-amount-impostos">
                <div class="card-amount-imposto">
                  <div style="text-align: left">
                    <div>Total a pagar</div>
                    <div
                      v-if="hasLastCalculatedIncomeTax && lastCalculatedIncomeTax.items[0].totalTaxNormalAndTaxDayTrade > 0"
                      style="margin-top: -1px; font-weight: bold; font-size: x-large"
                    >
                      {{ lastCalculatedIncomeTax.items[0].totalTaxNormalAndTaxDayTrade | CurrencyFormat }}
                    </div>
                    <div v-else style="margin-top: -1px">R$ 0,00</div>
                  </div>
                  <div style="text-align: right">
                    <div v-if="hasLastCalculatedIncomeTax">
                      IRRF Retido: <b>{{ ifFonte | CurrencyFormat }}</b>
                    </div>
                    <div v-else>
                      IRRF Retido: <b>{{ 0 | CurrencyFormat }}</b>
                    </div>
                    <div v-if="hasLastCalculatedIncomeTax">
                      Imposto devido: <b>{{ lastCalculatedIncomeTax.items[0].totalTaxNormalAndTaxDayTrade > 0 ? dueTax : 0 | CurrencyFormat }}</b>
                    </div>
                    <div v-else>
                      Imposto devido: <b>{{ 0 | CurrencyFormat }}</b>
                    </div>
                  </div>
                </div>
                <div class="button-container-imposto">
                  <!-- <md-button
                    :disabled="!hasLastCalculatedIncomeTax || !lastCalculatedIncomeTax.items[0].totalTaxNormalAndTaxDayTrade > 0 ? 'disabled' : ''"
                    style="width: 200px"
                    ><md-icon style="width: 6px; height: 6px; font-size: 20px !important">done</md-icon> Marcar como pago.</md-button
                  > -->
                  <md-button
                    v-if="profileIsLoaded && hasLastCalculatedIncomeTax && lastCalculatedIncomeTax.items[0].totalTaxNormalAndTaxDayTrade > 10"
                    style="width: 200px"
                    @click="showModalDarf = true"
                    class="md-warning">
                      <span> Gerar Darf</span>
                  </md-button>
                  <md-button v-else-if="!profileIsLoaded || (!hasLastCalculatedIncomeTax && lastCalculatedIncomeTax == null)" style="width: 200px" disabled="disabled"><span><div class="loading"></div></span></md-button>
                  <md-button v-else style="width: 200px" disabled="disabled"><span> Sem darf a pagar</span></md-button>
                </div>
              </div>
            </template>
            <template slot="footer">
              <div class="stats">
                <md-icon>analytics</md-icon>
                <span>Relatório de operações de ações</span>
              </div>
              <div class="stats">
                <md-icon>analytics</md-icon>
                <span>Relatório de operações de futuros</span>
              </div>
            </template>
          </stats-card>
        </div>

        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50">
          <stats-card header-color="apure">
            <template slot="header">
              <div style="text-align: left; font-size: x-large; font-weight: bold; margin-top: 2%">Mercado à Vista</div>
              <div style="text-align: left; font-family: arial">Acompanhe aqui o limite de isenção para operações no mercado à vista</div>
              <span>Limite para isenção: R$ 20.000,00</span>
              <div style="background-color: rgb(200 200 200 / 43%); height: 15px; padding: 15px; border-radius: 25px">
                <md-progress-bar md-mode="buffer" :md-value="progressAmount" :md-buffer=0></md-progress-bar>
              </div>

              <div style="margin-top: 2%; width: 100%; text-align: left">
                <div style="text-align: right">Total de venda:</div>
                <div style="text-align: right; font-weight: bold; font-size: x-large">
                  <span v-if="hasLastCalculatedIncomeTax" style="color: #f44336">
                    {{ lastCalculatedIncomeTax.items[0].totalMonthSell | CurrencyFormat }}
                  </span>
                  <span v-else style="color: #f44336"> R$ 0,00 </span>
                </div>
              </div>
              <template slot="footer"> </template>
            </template>
          </stats-card>
        </div>
      </div>
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33">
        <stats-card header-color="apure">
          <template slot="header">
            <div class="card-icon">
              <md-icon class="material-icons-two-tone">paid</md-icon>
            </div>
            <div style="text-align: left; font-family: arial; margin-top: 2%">Operações</div>
            <div style="text-align: left; font-size: x-large; font-weight: bold">Comuns</div>

            <div style="margin-top: 2%; width: 100%; text-align: left">
              <div style="text-align: right">Resultado</div>
              <div style="text-align: right; font-weight: bold; font-size: x-large">
                <span v-if="hasLastCalculatedIncomeTax && lastCalculatedIncomeTax.items[0].totalMonthGainNormal > 0" style="color: #66bb6a">
                  {{ lastCalculatedIncomeTax.items[0].totalMonthGainNormal | CurrencyFormat }}
                </span>
                <span v-else-if="hasLastCalculatedIncomeTax && lastCalculatedIncomeTax.items[0].totalMonthGainNormal < 0" style="color: #f44336">
                  {{ lastCalculatedIncomeTax.items[0].totalMonthGainNormal | CurrencyFormat }}
                </span>
                <span v-else> R$ 0,00 </span>
              </div>
              <div style="background-color: rgb(200 200 200 / 43%); height: fit-content; width: 100%">
                <ul style="list-style-type: none; padding: 0; border: 1px solid #ddd">
                  <div class="floatleft">
                    <li>
                      <i class="md-icon" style="font-size: 14px !important; line-height: 14px; width: 14px; height: 14px; text-align: left"> error_outline </i
                      >Prejuízo acumulado até {{ profile.last_calculated_income_tax | MonthYearFormatDate }}
                    </li>
                    <li>
                      <i class="md-icon" style="font-size: 14px !important; line-height: 14px; width: 14px; height: 14px; text-align: left"> error_outline </i
                      >Base de cálculo do imposto
                    </li>
                    <li>
                      <i class="md-icon" style="font-size: 14px !important; line-height: 14px; width: 14px; height: 14px; text-align: left"> error_outline </i
                      >Imposto
                    </li>
                  </div>
                  <div class="floatright">
                    <li v-if="hasLastCalculatedIncomeTax">
                      <span v-if="lastCalculatedIncomeTax.items[0].totalPreviousLossesNormal < 0" style="color: #f44336">
                        {{ lastCalculatedIncomeTax.items[0].totalPreviousLossesNormal | CurrencyFormat }}
                      </span>
                      <span v-else-if="lastCalculatedIncomeTax.items[0].totalPreviousLossesNormal > 0" style="color: #66bb6a">
                        {{ lastCalculatedIncomeTax.items[0].totalPreviousLossesNormal | CurrencyFormat }}
                      </span>
                      <span v-else>
                        {{ lastCalculatedIncomeTax.items[0].totalPreviousLossesNormal | CurrencyFormat }}
                      </span>
                    </li>
                    <li v-else>R$ 0,00</li>
                    <li v-if="hasLastCalculatedIncomeTax && lastCalculatedIncomeTax.items[0].totalPreviousLossesNormal >= 0" style="color: #66bb6a">
                      {{ lastCalculatedIncomeTax.items[0].totalGainNormal | CurrencyFormat }}
                    </li>
                    <li v-else>R$ 0,00</li>
                    <li v-if="hasLastCalculatedIncomeTax">
                      <span v-if="lastCalculatedIncomeTax.items[0].totalGainNormal > 0" style="color: #f44336">
                        {{ (lastCalculatedIncomeTax.items[0].totalGainNormal * 0.15) | CurrencyFormat }}
                      </span>
                      <span v-else> R$ 0,00 </span>
                    </li>
                    <li v-else>R$ 0,00</li>
                  </div>
                </ul>
              </div>
            </div>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33">
        <stats-card header-color="apure">
          <template slot="header">
            <div class="card-icon">
              <md-icon>currency_exchange</md-icon>
            </div>
            <div style="text-align: left; font-family: arial; margin-top: 2%">Operações</div>
            <div style="text-align: left; font-size: x-large; font-weight: bold">Day Trade</div>

            <div style="margin-top: 2%; width: 100%; text-align: left">
              <div style="text-align: right">Resultado</div>
              <div style="text-align: right; font-weight: bold; font-size: x-large">
                <span v-if="hasLastCalculatedIncomeTax && lastCalculatedIncomeTax.items[0].totalMonthGainDayTrade > 0" style="color: #66bb6a">
                  {{ lastCalculatedIncomeTax.items[0].totalMonthGainDayTrade | CurrencyFormat }}
                </span>
                <span v-else-if="hasLastCalculatedIncomeTax && lastCalculatedIncomeTax.items[0].totalMonthGainDayTrade < 0" style="color: #f44336">
                  {{ lastCalculatedIncomeTax.items[0].totalMonthGainDayTrade | CurrencyFormat }}
                </span>
                <span v-else> R$ 0,00 </span>
              </div>
              <div style="background-color: rgb(200 200 200 / 43%); height: fit-content; width: 100%">
                <ul style="list-style-type: none; padding: 0; border: 1px solid #ddd">
                  <div class="floatleft">
                    <li>
                      <i class="md-icon" style="font-size: 14px !important; line-height: 14px; width: 14px; height: 14px; text-align: left"> error_outline </i
                      >Prejuízo acumulado até {{ profile.last_calculated_income_tax | MonthYearFormatDate }}
                    </li>
                    <li>
                      <i class="md-icon" style="font-size: 14px !important; line-height: 14px; width: 14px; height: 14px; text-align: left"> error_outline </i
                      >Base de cálculo do imposto
                    </li>
                    <li>
                      <i class="md-icon" style="font-size: 14px !important; line-height: 14px; width: 14px; height: 14px; text-align: left"> error_outline </i
                      >Imposto
                    </li>
                  </div>
                  <div class="floatright">
                    <li v-if="hasLastCalculatedIncomeTax">
                      <span v-if="lastCalculatedIncomeTax.items[0].totalPreviousLossesDayTrade < 0" style="color: #f44336">
                        {{ lastCalculatedIncomeTax.items[0].totalPreviousLossesDayTrade | CurrencyFormat }}
                      </span>
                      <span v-else-if="lastCalculatedIncomeTax.items[0].totalPreviousLossesDayTrade > 0" style="color: #66bb6a">
                        {{ lastCalculatedIncomeTax.items[0].totalPreviousLossesDayTrade | CurrencyFormat }}
                      </span>
                      <span v-else>
                        {{ lastCalculatedIncomeTax.items[0].totalPreviousLossesDayTrade | CurrencyFormat }}
                      </span>
                    </li>
                    <li v-else>R$ 0,00</li>
                    <li v-if="hasLastCalculatedIncomeTax && lastCalculatedIncomeTax.items[0].totalPreviousLossesDayTrade > 0" style="color: #66bb6a">
                      {{ lastCalculatedIncomeTax.items[0].totalGainDayTrade | CurrencyFormat }}
                    </li>
                    <li v-else>R$ 0,00</li>
                    <li v-if="hasLastCalculatedIncomeTax">
                      <span v-if="lastCalculatedIncomeTax.items[0].totalGainDayTrade > 0" style="color: #f44336">
                        {{ (lastCalculatedIncomeTax.items[0].totalGainDayTrade * 0.2) | CurrencyFormat }}
                      </span>
                      <span v-else> R$ 0,00 </span>
                    </li>
                    <li v-else>R$ 0,00</li>
                  </div>
                </ul>
              </div>
            </div>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33">
        <stats-card header-color="apure">
          <template slot="header">
            <div class="card-icon">
              <md-icon>domain_add</md-icon>
            </div>
            <div style="text-align: left; font-family: arial; margin-top: 2%">Operações</div>
            <div style="text-align: left; font-size: x-large; font-weight: bold">Fundos Imobiliários</div>

            <div style="margin-top: 2%; width: 100%; text-align: left">
              <div style="text-align: right">Resultado</div>
              <div style="text-align: right; font-weight: bold; font-size: x-large">
                <span v-if="hasLastCalculatedIncomeTax && lastCalculatedIncomeTax.items[0].fii.totalMonthGainNormal > 0" style="color: #66bb6a">
                  {{ lastCalculatedIncomeTax.items[0].fii.totalMonthGainNormal | CurrencyFormat }}
                </span>
                <span v-else-if="hasLastCalculatedIncomeTax && lastCalculatedIncomeTax.items[0].fii.totalMonthGainNormal < 0" style="color: #f44336">
                  {{ lastCalculatedIncomeTax.items[0].fii.totalMonthGainNormal | CurrencyFormat }}
                </span>
                <span v-else> R$ 0,00 </span>
              </div>
              <div style="background-color: rgb(200 200 200 / 43%); height: fit-content; width: 100%">
                <ul style="list-style-type: none; padding: 0; border: 1px solid #ddd">
                  <div class="floatleft">
                    <li>
                      <i class="md-icon" style="font-size: 14px !important; line-height: 14px; width: 14px; height: 14px; text-align: left"> error_outline </i
                      >Prejuízo acumulado até {{ profile.last_calculated_income_tax | MonthYearFormatDate }}
                    </li>
                    <li>
                      <i class="md-icon" style="font-size: 14px !important; line-height: 14px; width: 14px; height: 14px; text-align: left"> error_outline </i
                      >Base de cálculo do imposto
                    </li>
                    <li>
                      <i class="md-icon" style="font-size: 14px !important; line-height: 14px; width: 14px; height: 14px; text-align: left"> error_outline </i
                      >Imposto
                    </li>
                  </div>
                  <div class="floatright">
                    <li v-if="hasLastCalculatedIncomeTax">
                      <span v-if="lastCalculatedIncomeTax.items[0].fii.totalPreviousLossesNormal < 0" style="color: #f44336">
                        {{ lastCalculatedIncomeTax.items[0].fii.totalPreviousLossesNormal | CurrencyFormat }}
                      </span>
                      <span v-else-if="lastCalculatedIncomeTax.items[0].fii.totalPreviousLossesNormal > 0" style="color: #66bb6a">
                        {{ lastCalculatedIncomeTax.items[0].fii.totalPreviousLossesNormal | CurrencyFormat }}
                      </span>
                      <span v-else>
                        {{ lastCalculatedIncomeTax.items[0].fii.totalPreviousLossesNormal | CurrencyFormat }}
                      </span>
                    </li>
                    <li v-else>R$ 0,00</li>
                    <li v-if="hasLastCalculatedIncomeTax && lastCalculatedIncomeTax.items[0].fii.totalPreviousLossesNormal > 0" style="color: #66bb6a">
                      {{ lastCalculatedIncomeTax.items[0].fii.totalGainNormal | CurrencyFormat }}
                    </li>
                    <li v-else>R$ 0,00</li>
                    <li v-if="hasLastCalculatedIncomeTax">
                      <span v-if="lastCalculatedIncomeTax.items[0].fii.totalGainNormal > 0" style="color: #f44336">
                        {{ (lastCalculatedIncomeTax.items[0].fii.totalGainNormal * 0.2) | CurrencyFormat }}
                      </span>
                      <span v-else> R$ 0,00 </span>
                    </li>
                    <li v-else>R$ 0,00</li>
                  </div>
                </ul>
              </div>
            </div>
          </template>
        </stats-card>
      </div>
      <modal-darf
        v-if="showModalDarf && hasLastCalculatedIncomeTax"
        :data="lastCalculatedIncomeTax.items[0]"
        :user="profile"
        :handleClose="handleCloseModal"
      ></modal-darf>
    </div>
  </div>
</template>

<script>
import { StatsCard } from '@/components'
import { ModalDarf } from '@/components'

export default {
  components: {
    StatsCard,
    ModalDarf,
  },

  data() {
    return {
      totalSellLimi: 20000,
      progressAmount: 0,
      profile: {},
      portfolio: {},
      hasLastCalculatedIncomeTax: null,
      lastCalculatedIncomeTax: {},
      profileIsLoaded: false,
      portfolioIsLoaded: false,
      totalPortfolio: 0,
      totalGain: 0,
      dailyGain: 0,
      gainPercent: 0,
      showModalDarf: false,
      irrf: 0,
      previousIrrf: 0,
      dueTax: 0,
      ifFonte: 0
    }
  },
  methods: {
    onSelect: function (items) {
      this.selected = items
    },
    async getProfile() {
      this.profile = await this.$store.dispatch('profile/me')
      this.profileIsLoaded = true
    },
    async getPortfolio() {
      const param = {
        token: this.$store.getters.token,
      }
      this.portfolio = await this.$store.dispatch('profile/getPortfolio', param)
      this.portfolio = await this.portfolio.items
      for (const result_key in this.portfolio) {
        for (const key in this.portfolio[result_key].portfolio) {
          var quantity = this.portfolio[result_key].portfolio[key].shares
          var price = this.portfolio[result_key].portfolio[key].averagePrice
          this.totalPortfolio = this.totalPortfolio + quantity * price
          this.totalGain = this.totalGain + this.portfolio[result_key].portfolio[key].gain
          this.dailyGain = this.dailyGain + this.portfolio[result_key].portfolio[key].dailyGain
        }
        this.gainPercent = (this.totalGain / this.totalPortfolio) * 100
      }
      this.portfolioIsLoaded = true
    },
    async getLastCalculatedIncomeTax() {
      const param = {
        token: this.$store.getters.token,
      }

      this.lastCalculatedIncomeTax = await this.$store.dispatch('profile/getLastCalculatedIncomeTax', param)
      this.hasLastCalculatedIncomeTax = this.lastCalculatedIncomeTax.totalItems > 0 ? true : false

      if (this.hasLastCalculatedIncomeTax && this.lastCalculatedIncomeTax.items[0].totalMonthSell < this.totalSellLimi) {
        this.progressAmount = this.totalSellLimi / this.lastCalculatedIncomeTax.items[0].totalMonthSell;
      } else {
        this.progressAmount = 100
      }
      this.irrf =
          this.lastCalculatedIncomeTax.items[0].totalDayTradeWithholdingIncomeTax + this.lastCalculatedIncomeTax.items[0].totalNormalWithholdingIncomeTax
        this.previousIrrf =
          this.lastCalculatedIncomeTax.items[0].totalNormalPreviousWithholdingIncomeTax +
          this.lastCalculatedIncomeTax.items[0].totalDayTradePreviousWithholdingIncomeTax
        this.dueTax = this.lastCalculatedIncomeTax.items[0].totalTaxNormalAndTaxDayTrade + (this.irrf + this.previousIrrf)

        this.ifFonte = (this.lastCalculatedIncomeTax.items[0].totalNormalWithholdingIncomeTax +
            this.lastCalculatedIncomeTax.items[0].totalDayTradeWithholdingIncomeTax +
            this.lastCalculatedIncomeTax.items[0].totalNormalPreviousWithholdingIncomeTax +
            this.lastCalculatedIncomeTax.items[0].totalDayTradePreviousWithholdingIncomeTax +
            this.lastCalculatedIncomeTax.items[0].fii.totalNormalWithholdingIncomeTax)
    },
    async handleCloseModal() {
      this.showModalDarf = false
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.getProfile()
      this.getPortfolio()
      this.getLastCalculatedIncomeTax()
    })
  },
}
</script>
<style lang="scss">
.title-page {
  font-size: 38px;
  font-weight: 700;
}
.md-icon-14 {
  font-size: 14px !important;
  line-height: 14px;
  width: 14px;
  height: 14px;
  text-align: left;
}
.floatleft {
  float: left;
  margin-top: 2%;
}
.floatright {
  float: right;
  margin-top: 2%;
}

.container-amount-impostos {
  margin-top: 15px;
}

.card-amount-imposto {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: rgb(200 200 200 / 43%);
  padding: 15px;
  border-radius: 25px;
}

.button-container-imposto {
  display: flex;
  flex-direction: row;
  margin-top: 3%;
  justify-content: flex-end;
}

@media (max-width: 690px) {
  #dashboard {
    .md-layout {
      padding: 0 10px;
    }
  }
}

@media (max-width: 590px) {
  .card-amount-imposto {
    flex-direction: column;
  }
  .button-container-imposto {
    justify-content: center;
  }
}
</style>