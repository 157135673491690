<template>
    <div v-if="!isLoading">
      <div class="header text-center">
        <h3>{{ profile.full_name }}</h3>
        <p>{{ profile.email }}</p>
        <md-button @click.prevent="showForm = true" v-show="!isLoading && !showForm" class="md-primary">
          Atualizar dados
        </md-button>
      </div>
      <div class="md-layout" v-if="showForm">
        <div class="md-layout-item">
          <md-card>
            <md-card-content>
              <form novalidate @submit.prevent="updateProfile">
                <div class="md-layout">
                  <div class="md-layout-item md-small-size-100 md-xsmall-size-100">
                    <md-field>
                      <label>NOME COMPLETO</label>
                      <md-input v-model="full_name" />
                      <span class="md-error" v-if="!$v.full_name.required">Este campo é obrigatório</span>
                      <span class="md-error" v-else-if="!$v.full_name.minLength">Insira um nome válido</span>
                      <validation-error :error="apiValidationErrors.full_name" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-100 md-xsmall-size-100">
                    <md-field>
                      <label>E-MAIL</label>
                      <md-input type="email" name="email" id="email" autocomplete="email" v-model="email" disabled/>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-100 md-xsmall-size-100">
                    <md-field>
                      <label for="phone">TELEFONE</label>
                      <md-input type="tel" name="phone" id="phone" autocomplete="phone" v-model="phone" v-mask="['(##) ####-####', '(##) #####-####']"/>
                      <validation-error :error="apiValidationErrors.phone" />
                    </md-field>
                  </div>
                </div>
                <div class="md-layout">
                  <div class="md-layout-item md-small-size-100 md-xsmall-size-100">
                    <md-datepicker v-model="birth_date">
                      <label>DATA DE NASCIMENTO</label>
                    </md-datepicker>
                    <validation-error :error="apiValidationErrors.bith_date" />
                  </div>
                  <div class="md-layout-item md-small-size-100 md-xsmall-size-100">
                    <md-field>
                      <label>GÊNERO</label>
                      <md-select v-model="gender" name="gender">
                        <md-option value="M"> MASCULINO</md-option>
                        <md-option value="F"> FEMININO</md-option>
                        <md-option value="O"> OUTROS</md-option>
                      </md-select>
                      <validation-error :error="apiValidationErrors.gender" />
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-100 md-xsmall-size-100">
                    <md-field>
                      <label>CPF/CNPJ</label>
                      <md-input v-model="cpfCnpj" disabled/>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout" style="margin-top: 2rem;">
                  <div class="md-layout-item md-small-size-100 md-xsmall-size-100">
                    <md-button class="md-raised md-primary" type="submit" :disabled="loading">SALVAR</md-button>
                  </div>
                </div>
              </form>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
  </template>
  <script>
  import formMixin from '@/mixins/form-mixin'
  
  import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
  import { ValidationError } from "@/components"
  import Swal from 'sweetalert2'
  import { mask } from 'vue-the-mask'
  import moment from 'moment'

export default {
    name: "Profile",
    directives: {mask},
    mixins: [formMixin],
    components: {ValidationError},
    data() {
      return {
        full_name: null,
        email: null,
        gender: null,
        cpfCnpj: null,
        birth_date: null,
        showForm: false,
        validForm: false,
        phone: null,
        profile: {},
        loading: true,
        errors: {},
      }
    },
    validations: {
      full_name: {
        required,
        minLength: minLength(3),
      },
      cpfCnpj: {
        required,
        minLength: minLength(14),
        maxLength: maxLength(19),
      },
      email: {
        required,
        email,
      },
    },
    methods: {
      async getProfile() {
        this.profile = await this.$store.dispatch('profile/me');
        this.full_name = this.profile.full_name;
        this.email = this.profile.email;
        this.gender = this.profile.gender;
        this.cpfCnpj = this.profile.cpf_cnpj;
        this.phone = this.profile.phone;
        this.birth_date = moment(this.profile.birth_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        this.loading = false;
      },
      async updateProfile() {
        const data = this;
        const profile = {
          id: data.profile.id,
          full_name: data.full_name,
          gender: data.gender,
          phone: data.phone,
          birth_date: moment(data.birth_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        };

        try {
          Swal.showLoading()
          await this.$store.dispatch("profile/update", profile);
          await this.$store.dispatch(
            "alerts/success",
            "Dados atualizados com sucesso!"
          );
        } catch (e) {
          await this.$store.dispatch(
            "alerts/error",
            "Oops, algo de errado aconteceu! Por favor, tente novamente."
          );
          this.setApiValidation(e.response.data.errors);
        } finally {
          Swal.close()
        }
      },
    },
    mounted: function () {
      this.$nextTick(function () {
        this.getProfile()
      })
    },
  }
  </script>
  