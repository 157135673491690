<template>
  <footer class="footer">
    <div class="container">
      <div class="text-center">
        Apure Bolsa, &copy; {{ new Date().getFullYear() }}. Todos os direitosreservados.
        <span class="footer-partner">
          Em parceria com &nbsp;
          <img src="https://mystocks.co/mystocks-1.svg" alt="Logo Mystock" class="footer-partner-logo">
        </span>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style lang="scss">
  .footer{
    font-size: 0.8rem;
    background-color: rgba(0, 0, 0, 0.15);
    display: flex;
    &-partner{
      @media screen and (max-width: 770px) {
        display: block;
      }
      &-logo{
        width: 80px;
      }
    }
  }
</style>
