import service from '@/store/services/profile-service'

const state = {
  me: null,
  profile: {
    id: null,
    full_name: null,
    cpf_cnpj: null,
    email: null,
    last_calculated_income_tax: null,
    last_brokerage_note: null,
    total_darf: null,
    total_calculated_income_tax: null,
    total_portifolio: null,
    current_subscription: null,
  },
  calculatedIncomeTax: {},
  operations: {},
  flexOption: {},
  futureTrade: {},
  notes: {},
  referral: null,
  brokerUser: null,
  clients: null,
  lastCalculatedIncomeTax: null,
  darf: null,
  portfolio: null,
  portfolioOthers: null,
  coupon: localStorage.getItem('coupon')
}

const mutations = {
  profile: (state, payload) => {
    state.profile = payload
  },
  calculatedIncomeTax: (state, payload) => {
    state.calculatedIncomeTax[payload.page] = payload.items
  },
  operations: (state, payload) => {
    var operationsFiltered = [payload.stockIds, payload.brokerId, payload.type, payload.page, payload.onlyClosed, payload.onlyDayTrade, payload.initialDate, payload.finalDate].join('_')
    state.operations[operationsFiltered] = payload.items
  },
  brokerUser: (state, payload) => {
    state.brokerUser = payload
  },
  flexOption: (state, payload) => {
    var flexOptionFiltered = [payload.page, payload.onlyClosed, payload.initialDate, payload.finalDate].join('_')
    state.flexOption[flexOptionFiltered] = payload.items
  },
  futureTrade: (state, payload) => {
    var futureTradeFiltered = [payload.page, payload.onlyClosed, payload.onlyDayTrade, payload.initialDate, payload.finalDate].join('_')
    state.futureTrade[futureTradeFiltered] = payload.items
  },
  resetCalculatedIncomeTax: (state, payload) => {
    state.calculatedIncomeTax = payload
  },
  resetOperations: (state, payload) => {
    state.operations = payload
  },
  resetNotes: (state, payload) => {
    state.notes = payload
  },
  portfolio: (state, payload) => {
    state.portfolio = payload
  },
  portfolioOthers: (state, payload) => {
    state.portfolioOthers = payload
  },
  notes: (state, payload) => {
    var notesFiltered = [payload.initialDate, payload.finalDate].join('_')
    state.notes[notesFiltered] = payload
  },
  lastCalculatedIncomeTax: (state, payload) => {
    state.lastCalculatedIncomeTax = payload
  },
  updateDarfPayday: (state, payload) => {
    state.darf = payload
  },
  referral: (state, payload) => {
    state.referral = payload
  },
  clients: (state, payload) => {
    state.clients = payload
  },
  earnings: (state, payload) => {
    state.earnings = payload
  },
}

const getters = {
  me: state => state.me,
  profile: state => state.profile,
  calculatedIncomeTax: state => state.calculatedIncomeTax,
  operations: state => state.operations,
  brokerUser: state => state.brokerUser,
  flexOption: state => state.flexOption,
  futureTrade: state => state.futureTrade,
  lastCalculatedIncomeTax: state => state.lastCalculatedIncomeTax,
  portfolio: state => state.portfolio,
  notes: state => state.notes,
  referral: state => state.referral,
  clients: state => state.clients,
  earnings: state => state.earnings,
  portfolio: state => state.portfolio,
  portfolioOthers: state => state.portfolioOthers,
}

const actions = {
  me({ commit, dispatch }, params) {
    if (state.profile.id != null) {
      return state.profile
    }
    return service.get(params).then(profile => {
      commit('profile', profile)
      return profile
    })
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then(profile => {
      commit('profile', profile)
    })
  },
  getCalculatedIncomeTax({ commit, dispatch }, params) {
    if (state.calculatedIncomeTax[params.page] == null) {
      commit('calculatedIncomeTax', { page: params.page, items: service.getCalculatedIncomeTax(params) })
    }
    return state.calculatedIncomeTax[params.page]
  },
  getLastCalculatedIncomeTax({ commit, dispatch }, params) {
    if (state.lastCalculatedIncomeTax == null) {
      commit('lastCalculatedIncomeTax', service.getLastCalculatedIncomeTax(params.token))
    }
    return state.lastCalculatedIncomeTax
  },
  updateDarfPayday({ commit, dispatch }, params) {
    commit('updateDarfPayday', service.updateDarfPayday(params))
    return state.darf
  },
  async getOperations({ commit, dispatch }, params) {
    var operationsFiltered = [params.stockIds, params.brokerId, params.type, params.page, params.onlyClosed, params.onlyDayTrade, params.initialDate, params.finalDate].join('_')
    if (state.operations[operationsFiltered] == null) {
      commit('operations', {
        stockIds: params.stockIds,
        brokerId: params.brokerId,
        type: params.type,
        page: params.page,
        onlyClosed: params.onlyClosed,
        onlyDayTrade: params.onlyDayTrade,
        initialDate: params.initialDate,
        finalDate: params.finalDate,
        items: await service.getOperations(params),
      })
    }
    return state.operations[operationsFiltered]
  },
  getBrokerUser({ commit, dispatch }, params) {
      commit('brokerUser', service.getBrokerUser(params))
      return state.brokerUser
  },  
  getFlexOption({ commit, dispatch }, params) {
    var operationsFiltered = [params.page, params.onlyClosed, params.initialDate, params.finalDate].join('_')
    if (state.flexOption[operationsFiltered] == null) {
      commit('flexOption', {
        page: params.page,
        onlyClosed: params.onlyClosed,
        initialDate: params.initialDate,
        finalDate: params.finalDate,
        items: service.getFlexOption(params),
      })
    }
    return state.flexOption[operationsFiltered]
  },
  getFutureTrade({ commit, dispatch }, params) {
    var operationsFiltered = [params.page, params.onlyClosed, params.onlyDayTrade, params.initialDate, params.finalDate].join('_')
    if (state.futureTrade[operationsFiltered] == null) {
      commit('futureTrade', {
        page: params.page,
        onlyClosed: params.onlyClosed,
        onlyDayTrade: params.onlyDayTrade,
        initialDate: params.initialDate,
        finalDate: params.finalDate,
        items: service.getFutureTrade(params),
      })
    }
    return state.futureTrade[operationsFiltered]
  },
  getPortfolio({ commit, dispatch }, params) {
    if (state.portfolio == null) {
      commit('portfolio', service.getPortfolio(params.token))
    }
    return state.portfolio
  },
  getPortfolioOthers({ commit, dispatch }, params) {
    if (state.portfolioOthers == null) {
      commit('portfolioOthers', service.getPortfolioOthers(params.token))
    }
    return state.portfolioOthers
  },
  getNotes({ commit, dispatch }, params) {
    var notesFiltered = [params.initialDate, params.finalDate].join('_')
    if (state.notes[notesFiltered] == null) {
      commit('notes', {
        initialDate: params.initialDate,
        finalDate: params.finalDate,
        items: service.getNotes(params),
      })
    }
    return state.notes[notesFiltered]
  },
  getReferral({ commit, dispatch }, params) {
    commit('referral', {
      items: service.getReferral(params),
    })
    return state.referral
  },
  getClients({ commit, dispatch }, params) {
    commit('clients', {
      items: service.getClients(params),
    })
    return state.clients
  },
  getEarnings({ commit, dispatch }, params) {
    commit('earnings', {
      items: service.getEarnings(params),
    })
    return state.earnings
  },
  getPortfolio({ commit, dispatch }, params) {
    commit('portfolio', {
      items: service.getPortfolio(params),
    })
    return state.portfolio
  },
  getPDF({commit, dispatch}, params){
    return service.getPDF(params)
  },
  resetState({commit, dispatch}, params) {
    commit('me', null,);
    commit('profile', {
      id: null,
      full_name: null,
      cpf_cnpj: null,
      email: null,
      last_calculated_income_tax: null,
      last_brokerage_note: null,
      total_darf: null,
      total_calculated_income_tax: null,
      total_portifolio: null,
      current_subscription: null,
    })
    commit('resetCalculatedIncomeTax', {})
    commit('resetOperations', {})
    commit('resetNotes', {})
    commit('portfolio', null)
    commit('portfolioOthers', null)
    commit('lastCalculatedIncomeTax', null)
    commit('referral', null)
  },
}

const profile = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default profile
