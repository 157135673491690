<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <!-- <h3 class="md-title" v-if="(profileIsLoaded && profile.last_brokerage_note)">
          Última nota importada: (<strong> {{ profile.last_brokerage_note | DateFormat }} </strong>).
          <i class="md-icon md-icon-font text-danger md-theme-default">warning</i >
        </h3>
        <div v-else-if="!profileIsLoaded" class="loading"></div> -->
      </div>

      <div class="md-toolbar-section-end">
        <div class="md-collapse">
          <md-button class="md-warning" v-if="profileIsLoaded && !profile.active_plan" href="/assinar-plano">Assine agora</md-button>
          <md-list>
            <li class="md-list-item">
              <a class="md-list-item-router md-list-item-container md-button-clean dropdown">
                <div class="md-list-item-content">
                  <drop-down direction="down">
                    <md-button slot="title" class="md-button md-just-icon md-simple" data-toggle="dropdown" v-if="profileIsLoaded">
                      <md-icon>person</md-icon>
                    </md-button>
                    <div v-else class="loading"></div>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li class="profile">
                        <h6 class="profile-title">{{ profile.first_name }} {{ profile.last_name }}</h6>
                        <small class="profile-email">{{ profile.email }}</small>
                      </li>
                      <li>
                        <router-link to="minha-conta" class="text-center">Minha conta</router-link>
                      </li>
                      <li>
                        <a class="text-center header-menu-logout" @click="logout">Sair</a>
                      </li>
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li>
          </md-list>
        </div>

        <md-button class="md-just-icon md-round md-simple md-toolbar-toggle" :class="{ toggled: $sidebar.showSidebar }" @click="toggleSidebar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
export default {
  data() {
    return {
      profile: {},
      documentType: 'CPF',
      profileIsLoaded: false,
    }
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    },
    logout() {
      this.$store.dispatch('profile/resetState', {})
      this.$store.dispatch('logout', {})
    },
    async getProfile() {
      this.profile = await this.$store.dispatch('profile/me');
      console.log(this.profile.cpf_cnpj.lenth);
      if(this.profile.cpf_cnpj.lenth == 18){
        this.documentType = "CNPJ";
      }
      this.profileIsLoaded = true
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.getProfile()
    })
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 990px) {
  .btn-assine-mobile {
    display: none;
  }

  .dropdown-menu{
    padding: 20px;
  }

  .profile{
    padding-bottom: 10px;
    text-align: center;
  }
  .profile-title{
    margin: 0;
    text-align: center;
  }
  .profile-email{
    text-align: center;
    text-transform: lowercase;
  }
}
</style>
