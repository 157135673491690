<template>
  <div>
    <div class="header text-center">
      <h3 class="title">Meus clientes</h3>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-content>
            <md-table v-if="isClientsLoaded" v-model="searched" md-card md-sort="full_name" md-sort-order="asc">
              <md-table-toolbar>
                <h1 class="md-title">Clientes</h1>
                <md-field md-clearable class="md-toolbar-section-end">
                  <md-input placeholder="Filtrar por nome..." v-model="search" @input="searchOnTable" />
                </md-field>
              </md-table-toolbar>
              <md-table-empty-state
                md-label="Nenhum cliente encontrado..."
                :md-description="`Nenhum cliente enconrado com a busca '${search}'.`">
              </md-table-empty-state>
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Nome do cliente" data-title="Nome">{{ item.full_name}}</md-table-cell>
                <md-table-cell md-label="CPF / CNPJ" data-title="CPF">{{ item.cpf_cnpj}}</md-table-cell>
                <md-table-cell md-label="Plano" data-title="Plano" v-if="item.plan_name">{{ item.plan_name }}</md-table-cell>
                <md-table-cell md-label="Plano" data-title="Plano" v-else> - </md-table-cell>
                <md-table-cell md-label="Vencimento" data-title="Vencimento">{{ item.expiration_plan | DateFormat}}</md-table-cell>
                <md-table-cell md-label="Está Vencido?" data-title="Está Vencido?" v-if="item.expired_plan">SIM</md-table-cell>
                <md-table-cell md-label="Está Vencido?" data-title="Está Vencido?" v-else>NÃO</md-table-cell>
                <md-table-cell md-label="Acessar o painel do cliente" data-title="Acessar painel">
                  <md-button style="width: fit-content;" class="md-raised md-primary" @click="goToDashboard(item.id)" type="button">Acessar painel</md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.full_name).includes(toLower(term)) || toLower(item.cpf_cnpj).includes(toLower(term)))
  }

  return items
}


export default {
    data: () => ({
      clients: [],
      pageSize: 1000,
      loading: false,
      qtdTotal: 0,
      page: 1,
      isMobile: false,
      isClientsLoaded: false,
      searched: [],
      search: ''

    }),
    methods: {
      async searchOnTable () {
        this.searched = searchByName(this.clients, this.search)
        setTimeout(function () {
            Swal.close();
            this.isClientsLoaded = true;
        }, 500);
      },
      async getClients(page) {
        Swal.showLoading();
        try {
          var clients = null
          this.loading = true
          this.params = {
            tokenPartner: this.$store.getters.tokenPartner,
            page,
            pageSize: this.pageSize,
          }
          clients = await this.$store.dispatch('profile/getClients', this.params)
          clients = await clients.items
          this.qtdTotal = clients.count
          this.clients = this.clients.concat(clients.items)
          this.searched = this.clients;
          this.loading = false
          this.isClientsLoaded = true;
        } catch (error) {
          await this.$store.dispatch("alerts/error", "Ocorreu um erro, tente novamente.");
        } finally {
          setTimeout(function () {
            Swal.close();
          }, 500);
        }
        
      },
      async seeMore() {
        this.page += 1
        this.getClients(this.page)
      },
      async goToDashboard(personId) {
        Swal.showLoading();
        try {
          const user = {
            person_id: personId
          };

          const requestOptions = {
            headers: {
              'Authorization': 'Bearer ' + this.$store.getters.tokenPartner
            },
          };
        var client = await this.$store.dispatch('loginAsClient', { user, requestOptions })
        window.open('https://app.apurebolsa.com.br', '_blank');
        console.log(client)
        } catch (error) {
          await this.$store.dispatch("alerts/error", "Ocorreu um erro, tente novamente.");
        } finally {
          setTimeout(function () {
            Swal.close();
          }, 500);
        }
      }
    },
    mounted() {
      this.isMobile = window.innerWidth < 790;
    },
    beforeMount: function () {
      this.$nextTick(function () {
        this.getClients(this.page)
        $('.sidebar').hide()
        $('.md-toolbar').hide()
      })
    },
};
</script>
<style>
</style>
