<template>
  <div>
    <div class="header text-center">
      <h3 class="title-page">Meus indicados</h3>
    </div>

    <div class="md-layout md-layout-item" :style="isMobile ? 'padding: 0 12px' : 'padding: 0 20px'">
      <md-table v-model="referral" :md-card="!isMobile" md-sort="name" md-sort-order="asc">
        <md-table-toolbar>
          <h1 class="md-title">Meus indicados</h1>
        </md-table-toolbar>

        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Nome" data-title="Nome">{{ item.first_name }}</md-table-cell>
          <md-table-cell md-label="CPF/CNPJ" data-title="CPF/CNPJ">{{ item.cpf_cnpj }}</md-table-cell>
          <md-table-cell md-label="Data da última nota" data-title="Data da última nota">{{ item.last_brokerage_note | DateFormat }}</md-table-cell>
          <md-table-cell md-label="Data da criação da conta" data-title="Data da criação da conta">{{ item.created_at | DateFormat }}</md-table-cell>
          <md-table-cell md-label="Último valor pago" data-title="Último valor pago">{{ item.last_cashback | CurrencyFormat }}</md-table-cell>
          <md-table-cell md-label="Status" data-title="Status">{{ item.status || 'Inativo' }}</md-table-cell>
        </md-table-row>
      </md-table>
      <md-button @click="seeMore" v-if="referral.length < qtdTotal" :disabled="loading">Ver mais</md-button>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    referral: [],
    pageSize: 10,
    loading: false,
    qtdTotal: 0,
    page: 1,
    isMobile: false,
  }),
  methods: {
    async getReferral(page) {
      var referral = null
      this.loading = true
      this.params = {
        token: this.$store.getters.token,
        page,
        pageSize: this.pageSize,
      }
      referral = await this.$store.dispatch('profile/getReferral', this.params)
      referral = await referral.items
      this.qtdTotal = referral.count
      this.referral = this.referral.concat(referral.items)
      this.loading = false
    },
    async seeMore() {
      this.page += 1
      this.getReferral(this.page)
    },
  },
  mounted() {
    this.isMobile = window.innerWidth < 790
  },
  beforeMount: function () {
    this.$nextTick(function () {
      this.getReferral(this.page)
    })
  },
}
</script>
<style lang="scss">
</style>
