<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <form novalidate @submit.prevent="register" v-if="isRegisterStep">
        <signup-card>
          <h2 class="title text-center" slot="title">Faça seu cadastro!</h2>
          <div class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 ml-auto" slot="content-left">
            <div class="info info-horizontal" v-for="item in contentLeft" :key="item.title">
              <div :class="`icon ${item.colorIcon}`">
                <md-icon>{{ item.icon }}</md-icon>
              </div>
              <div class="description">
                <h4 class="info-title">{{ item.title }}</h4>
                <p class="description">
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 mr-auto" slot="content-right">
            <h6>Todos os campos são obrigatórios</h6>
            <md-field class="md-form-group md-invalid" style="margin-bottom: 2rem">
              <md-icon>face</md-icon>
              <label>NOME COMPLETO</label>
              <md-input v-model="full_name" />
              <span class="md-error" v-if="!$v.full_name.minLength">Insira um nome válido</span>
              <validation-error :error="apiValidationErrors.full_name" />
            </md-field>

            <md-field class="md-form-group md-invalid" style="margin-bottom: 2rem">
              <md-icon>email</md-icon>
              <label>E-MAIL</label>
              <md-input id="email" v-model="email" />
              <validation-error :error="apiValidationErrors.email" />
              <span class="md-error" v-if="$v.email.required && $v.email.$invalid">Insira um e-mail válido</span>
            </md-field>

            <md-field class="md-form-group md-invalid" style="margin-bottom: 2rem">
              <md-icon>badge</md-icon>
              <label>CPF / CNPJ</label>
              <md-input id="cpfCnpj" v-model="cpfCnpj" />
              <validation-error :error="apiValidationErrors.cpf_cnpj" />
              <span class="md-error" v-if="$v.cpfCnpj.required && $v.cpfCnpj.$invalid">CNPJ ou CPF inválido</span>
            </md-field>
            <md-field class="md-form-group md-invalid" style="margin-bottom: 2rem">
              <md-icon>phone</md-icon>
              <label for="phone">TELEFONE</label>
              <md-input type="tel" name="phone" id="phone" autocomplete="phone" v-model="phone" v-mask="['+55 (##) ####-####', '+55 (##) #####-####']"/>
              <validation-error :error="apiValidationErrors.phone" />
            </md-field>
            <md-field class="md-form-group md-invalid" style="margin-bottom: 2rem">
              <md-icon>account_circle</md-icon>
              <label for="gender">GÊNERO</label>
              <md-select v-model="gender" name="gender" id="gender">
                <md-option value="M">MASCULINO</md-option>
                <md-option value="F">FEMINIMO</md-option>
                <md-option value="O">OUTROS</md-option>
              </md-select>
              <validation-error :error="apiValidationErrors.gender" />
            </md-field>

            <md-field class="md-form-group md-invalid" style="margin-bottom: 2rem">
              <md-icon>lock_outline</md-icon>
              <label>SENHA</label>
              <md-input @blur="validateFields" v-model="password" type="password" />
              <span class="md-error" v-if="$v.password.required && $v.password.$invalid">A senha deve conter no minimo 6 digitos</span>
              <validation-error :error="apiValidationErrors.password" />
            </md-field>

            <md-field class="md-form-group md-invalid">
              <md-icon>lock_outline</md-icon>
              <label>CONFIRME SUA SENHA</label>
              <md-input @blur="validateFields" v-model="confirmation_password" type="password" />
              <validation-error :error="apiValidationErrors.confirmation_password" />
            </md-field>
            <md-field>
              <md-input type="hidden" v-model="referral_code" disabled />
            </md-field>
            <md-field v-if="referral_code && referral_name" class="md-form-group md-invalid" style="margin-bottom: 2rem">
              <md-icon>co_present</md-icon>
              <label>INDICADO POR:</label>
              <md-input v-model="referral_name" disabled />
            </md-field>
            <div style="display: flex">
              <md-switch style="margin-right: 1%" v-model="terms_conditions">Eu aceito os </md-switch
              ><a style="margin-top: 3.5%; font-weight: bold" href="https://apurebolsa.com.br/painel/terms.html" target="_blank">termos e condições</a>.
            </div>
            <div style="display: flex">
              <md-switch style="margin-right: 1%" v-model="privacy">Eu aceito as </md-switch
              ><a style="margin-top: 3.5%; font-weight: bold" href="https://apurebolsa.com.br/painel/privacy.html#" target="_blank">politicas de privacidade</a
              >.
            </div>
            <validation-error :error="apiValidationErrors.terms" />
            <div class="button-container">
              <md-button 
                class="md-success md-round mt-4" 
                type="submit" 
                slot="footer" 
                :disabled="$v.$invalid || (apiValidationErrors.confirmation_password || apiValidationErrors.password)"> Cadastrar </md-button>
            </div>
          </div>
        </signup-card>
      </form>
      <div v-if="!isRegisterStep">
        <h2 class="title text-center" slot="title">Bem vindo!</h2>
        <div class="md-layout-item">
          {{ referral_name }} recomendou que você utilize nossos serviços. Se você aceitar essa recomendação, sinta-se à vontade para prosseguir com o cadastro. Se tiver alguma dúvida sobre o que oferecemos, não hesite em nos contatar através do botão abaixo. Estamos à disposição para ajudar!
        </div>
        <md-button class="md-warning md-round mt-4" @click="isRegisterStep = true">Continuar o cadastro</md-button>
        <md-button v-if="whatsappMessageIsLoaded" class="md-success md-round mt-4" @click="contact">Entre em contato no WhatsApp</md-button>
      </div>
    </div>
  </div>
</template>
<script>
import { SignupCard, ValidationError } from '@/components'
import formMixin from '@/mixins/form-mixin'
import Swal from 'sweetalert2'
import { mask } from 'vue-the-mask'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
const mustChecked = (value) => value;

export default {
  components: {
    SignupCard,
    ValidationError,
  },
  directives: {mask},
  mixins: [formMixin],
  data() {
    return {
      full_name: null,
      firstLoad: true,
      terms_conditions: false,
      privacy: false,
      email: null,
      phone: null,
      gender: null,
      cpfCnpj: null,
      password: null,
      confirmation_password: null,
      referral_code: null,
      coupon: null,
      referral_name: null,
      validForm: false,
      isRegisterStep: true,
      whatsappMessage: null,
      whatsappMessageUrl: null,
      whatsappMessageIsLoaded: false,
      errors: [
        {
          detail: '',
          code: '',
          field: null
        }
      ],
      contentLeft: [
        {
          colorIcon: 'icon-success',
          icon: 'timeline',
          title: 'Pare de quebrar a cabeça!',
          description:
            'Com a Apure Bolsa é só você enviar suas notas de corretagem e extratos disponibilizados pelas corretoras, o que levará apenas alguns minutos, e depois é só receber de volta, relatórios completos e de fácil entendimento, no padrão da Receita Federal, sobre suas movimentações na Bolsa.',
        },
      ],
    }
  },
  validations: {
    full_name: {
      required,
      minLength: minLength(3),
    },
    password: {
      required,
      minLength: minLength(6),
    },
    confirmation_password: {
      required,
    },
    gender: {
      required,
    },
    phone: {
      required,
    },
    cpfCnpj: {
      required,
      minLength: minLength(14),
      maxLength: maxLength(19),
    },
    email: {
      required,
      email,
    },
    terms_conditions: {
      mustChecked,
    },
    privacy: {
      mustChecked,
    }
  },
  methods: {
    async getReferralName() {
      const requestOptions = {
        headers: {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
        },
      }
      const referral = {
        code: this.referral_code,
      }
      this.referral_name = await this.$store.dispatch('getReferralName', { referral, requestOptions });
      var mobile = (/iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase()));
      this.whatsappMessage = "Olá, fui indicado";
      if(this.referral_name){
        this.whatsappMessage = this.whatsappMessage + " por " + this.referral_name;
      }
      this.whatsappMessage = this.whatsappMessage + " e gostaria de saber mais sobre o serviço."
      if (mobile) {
          this.whatsappMessageUrl = "https://api.whatsapp.com/send?phone=556293208182&text=" + this.whatsappMessage;
      }else{
        this.whatsappMessageUrl = "https://web.whatsapp.com/send?phone=556293208182&text=" + this.whatsappMessage;
      }
      this.whatsappMessageIsLoaded = true;
      this.isRegisterStep = false;
      this.firstLoad = false;
    },
    validateFields() {
      this.errors = [
        {
          detail: '',
          code: '',
          field: null
        }
      ]
      const regexPassword = /^(?=.*[0-9])(?=.*[a-zA-Z]).*$/

      if (!regexPassword.test(this.password)) { 
        this.errors[0].detail = 'A senha precisa ter ao menos 1 letra e um 1 número';
        this.errors[0].field = 'password';
      }

      if (this.password !== this.confirmation_password){
        this.errors[0].detail = 'As senhas não coincidem';
        this.errors[0].field = 'confirmation_password';
      }

      this.setApiValidation(this.errors);
      if (this.errors.length === 0) this.validForm = true;
      // else await this.$store.dispatch('alerts/error', 'Verifique os campos ')
    },
    async contact (){
      window.open(this.whatsappMessageUrl);
    },
    async register() {
      var this_ori = this;
      this_ori.firstLoad = false
      this_ori.$v.$touch()
      this_ori.validateFields()

      const user = {
        full_name: this_ori.full_name,
        email: this_ori.email,
        gender: this_ori.gender,
        phone: this_ori.phone,
        cpf_cnpj: this_ori.cpfCnpj,
        password: this_ori.password,
        confirmation_password: this_ori.confirmation_password,
        referral_code: this_ori.referral_code,
        terms_conditions: this_ori.terms_conditions,
        privacy: this_ori.privacy,
        coupon: this_ori.coupon,
      }

      const requestOptions = {
        headers: {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
        },
      }
      try {
        Swal.showLoading()
        await this_ori.$store.dispatch('send_code', { user, requestOptions })
      } catch (e) {
        this_ori.setApiValidation(e.response.data);
        console.log(this_ori.apiValidationErrors)
        await this_ori.$store.dispatch('alerts/error', 'Erro ao enviar dados, tente novamente.')
      } finally {
        Swal.close()
      }
    },
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    this.$store.redirectTo = params.get('redirectTo');
    this.$store.selectedPlan = params.get('planCode');
    
    this.referral_code = this.$route.query.referralCode
    if (this.referral_code) {
      this.getReferralName();
    }
    if(!!localStorage.getItem('coupon')) {
      this.coupon = localStorage.getItem('coupon');
    }

    $(document).ready(function ($) {
      var options = {
        onKeyPress: function (cpf, ev, el, op) {
          var masks = ['000.000.000-000', '00.000.000/0000-00']
          $('#cpfCnpj').mask(cpf.length > 14 ? masks[1] : masks[0], op)
        },
      }

      $('#cpfCnpj').length > 11 ? $('#cpfCnpj').mask('00.000.000/0000-00', options) : $('#cpfCnpj').mask('000.000.000-00#', options)
    })
  },
}
</script>
