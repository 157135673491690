<template>
  <div>
    <div class="header text-center">
      <h3 class="title">Proventos</h3>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-content>
            <md-table v-if="isEarningsLoaded" v-model="earnings" md-card md-sort="payday" md-sort-order="desc">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Ativo" data-title="Ativo">{{ item.stockId }}</md-table-cell>
                <md-table-cell md-label="Corretora" data-title="Corretora">{{ item.broker.name }}</md-table-cell>
                <md-table-cell md-label="Tipo" data-title="Tipo">{{ item.earningsType.description }}</md-table-cell>
                <md-table-cell md-label="Pagamento" data-title="Pagamento">{{ item.payday | DateFormat}}</md-table-cell>
                <md-table-cell md-label="Quantidade" data-title="Quantidade">{{ item.shares | TruncateFormat }}</md-table-cell>
                <md-table-cell md-label="Valor por Ativo" data-title="Valor por Ativo">{{ item.valuePerShare | ValueFormat }}</md-table-cell>
                <md-table-cell md-label="Total" data-title="Total">{{ item.total | ValueFormat }}</md-table-cell>
                <md-table-cell md-label="Yield" data-title="Yield">{{ item.yield * 100 | ValueFormat }}%</md-table-cell>
                <md-table-cell md-label="Yield on Cost" data-title="Yield on Cost">{{ item.yieldOnCost * 100 | ValueFormat }}%</md-table-cell>
              </md-table-row>
            </md-table>
            <md-button v-if="!loading" class="md-raised md-success md-lg" @click="seeMore">Ver Mais</md-button>
          <md-button v-else class="md-raised md-success md-lg" disabled>Carregando...</md-button>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'

export default {
    data: () => ({
      earnings: [],
      pageSize: 100,
      loading: false,
      page: 1,
      isMobile: false,
      isEarningsLoaded: false
    }),
    methods: {
      async getEarnings(page) {
        Swal.showLoading();
        try {
          var earnings = null
          this.loading = true
          this.params = {
            token: this.$store.getters.token,
            page,
            pageSize: this.pageSize,
          }
          earnings = await this.$store.dispatch('profile/getEarnings', this.params)
          earnings = await earnings.items
          this.earnings = this.earnings.concat(earnings.items)
          this.loading = false
          this.isEarningsLoaded = true;
        } catch (error) {
          await this.$store.dispatch("alerts/error", "Ocorreu um erro, tente novamente.");
        } finally {
          setTimeout(function () {
            Swal.close();
          }, 500);
        }
        
      },
      async seeMore() {
        this.page += 1
        this.getEarnings(this.page)
      },
    },
    mounted() {
      this.isMobile = window.innerWidth < 790;
    },
    beforeMount: function () {
      this.$nextTick(function () {
        this.getEarnings(this.page);
      })
    },
};
</script>
<style>
</style>
