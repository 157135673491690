<template>
  <router-view></router-view>
</template>

<script>
export default {
  data: () => ({
  }),
  methods: {
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    this.$store.coupon = params.get('coupon');
    if (this.$store.coupon) {
      localStorage.setItem('coupon', this.$store.coupon)
    }
  },
};
</script>
