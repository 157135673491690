<template>
  <span v-if="error" class="md-error">
    {{ error.detail }}
  </span>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss">
.md-field {
  .md-error {
    bottom: -8px;
  }
}
</style>
