<template>
  <div class="md-layout text-center login-fix-page">
    <div class="md-layout-item md-size-100">
      <div class="text-center">
        <h3>
          <img
            src="https://apurebolsa.com.br/assets/images/logoapb.png"
            height="100"
            width="100"
          />
        </h3>
        <p>
          Foque nos seus investimentos com tranquilidade!
        </p>
      </div>
      <div class="text-center" style="margin-bottom: 32px;"></div>
    </div>

    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <form @submit.prevent="login">
        <login-card header-color="apure">
          <h4 slot="title" class="title">Informe suas credenciais</h4>
          <md-field
            class="form-group md-invalid"
            slot="inputs"
            style="margin-bottom: 28px"
          >
            <md-icon>email</md-icon>
            <label>Email</label>
            <md-input v-model="username" type="email" />
          </md-field>
          <md-field class="form-group md-invalid" slot="inputs">
            <md-icon>lock_outline</md-icon>
            <label>Senha</label>
            <md-input v-model="password" type="password" />
            <validation-error :error="apiValidationErrors.null" />
          </md-field>
          <md-button
            @click="sendForgotCode"
            slot="footer"
            type="button"
            class="md-simple md-warning md-lg"
          >
            Esqueci senha
          </md-button>
          <md-button
            slot="footer"
            type="submit"
            class="md-simple md-success md-lg"
            :disabled="password.length < 5 && username.length < 5"
          >
            Efetuar login
          </md-button>
        </login-card>
      </form>
    </div>
  </div>
</template>

<script>
import { LoginCard, ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";
import Swal from "sweetalert2";

export default {
  components: {
    LoginCard,
    ValidationError,
  },

  mixins: [formMixin],
  data() {
    return {
        username: '',
        password: '',
    };
  },

  methods: {
    async sendForgotCode() {
      this.$router.push({ name: "ForgotPassword" });
    },
    async login() {
      const user = {
        email: this.username,
        password: this.password,
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

      try {
        const this_ori = this;
        Swal.showLoading();
        await this.$store.dispatch("loginPartner", { user, requestOptions });
        Swal.fire({
          icon: 'success',
          html: 'Aguarde, estamos carregando a lista de clientes!',
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading()
          }
        });
        setTimeout(function(){
          this_ori.$router.push({path: "/meus-clientes"});
        }, 2000);
      } catch (e) {
        await this.$store.dispatch("alerts/error", "Login ou senha errado!");
        this.setApiValidation(e.response.data);
      }finally {
        setTimeout(function(){
          Swal.close();
        }, 1800);
      }
    },
  },
};
</script>
<style scoped>
.login-fix-page {
  padding-bottom: 7em;
  padding-top: 4em;
}
</style>
