<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <form @submit.prevent="register">
        <signup-card>
          <h2 class="title text-center" slot="title">Confirme seu e-mail!</h2>
          <div
            class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 mr-auto"
            slot="content-right"
          >
            <md-field
              class="md-form-group md-invalid"
              style="margin-bottom: 2rem"
            >
              <md-icon>mark_email_read</md-icon>
              <label>Código de confirmação</label>
              <md-input v-model="client_code" /> 
              <validation-error :error="apiValidationErrors.client_code" />
            </md-field>

            <div class="button-container">
              <md-button
                class="md-success md-round mt-4"
                type="submit"
                slot="footer"
              >
                Confirmar
              </md-button>
            </div>
          </div>
        </signup-card>
      </form>
    </div>
  </div>
</template>
<script>
import { SignupCard, ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";
import Swal from "sweetalert2";

export default {
  components: {
    SignupCard,
    ValidationError,
  },
  mixins: [formMixin],
  data() {
    return {
        confirmation_hash: null,
        client_code: null
    };
  },

  methods: {
    async register() {
        const this_ori = this;
        const user = {
            confirmation_hash: this.$store.getters.confirmationHash,
            client_code: this.client_code
        };

        const requestOptions = {
            headers: {
              Accept: "application/vnd.api+json",
              "Content-Type": "application/vnd.api+json",
            },
        };
        try{
          Swal.showLoading();
          await this_ori.$store.dispatch("register", { user, requestOptions });
          Swal.fire({
            icon: "success",
            html: "Cadastro realizado com sucesso",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          
          setTimeout(function () {
            if(!!this_ori.$store.redirectTo){
              this_ori.$router.push({ path: this_ori.$store.redirectTo });
            } else {
              this_ori.$router.push({ path: "/" });
            }
          }, 2000);
        } catch (e) {
          await this_ori.$store.dispatch(
            "alerts/error",
            "Erro ao enviar dados, tente novamente."
          );
          console.log(e);
        } finally {
          setTimeout(function () {
            Swal.close();
          }, 2000);
        }
    },
  },
};
</script>
