<template>
  <div id="refferal">
    <div class="header text-center">
      <h3 class="title-page">Indicação</h3>
    </div>
    <div id="card-indication" class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <div class="text-center">
            <img class="gift-img text-center" src="../../../assets/images/gift-box.png" />
          </div>
          <md-card-content>
            <div class="header text-center">
              <h4 class="title" style="text-align: center; font-weight: bold; margin-top: 30px">
                Indique a Apure Bolsa e ganhe mensalmente com cada assinatura!
              </h4>
            </div>
            <div class="copy-container">
              <h4 style="text-align: center; font-weight: bold; font-size: 14px">Copie e envie seu link de indicação</h4>
              <div style="display: flex; justify-content: center">
                <div class="copy-content">
                  {{ urlReferral || '...' }}
                  <md-button @click="copyLink" :class="copied ? 'md-success' : 'md-primary'"
                    >{{ copied ? 'Copiado' : 'Copiar' }}
                    <md-icon style="margin-left: 6px" v-if="!copied">content_copy</md-icon>
                    <md-icon style="margin-left: 6px" v-else>check</md-icon>
                  </md-button>
                  <md-tooltip md-direction="right">
                    O indicado deverá utilizar o seu link para se cadastrar na plataforma e assinar um dos planos. Indique quantas pessoas quiser, não há
                    limites por link.
                  </md-tooltip>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div id="detail-indication" class="md-layout" style="margin-top: -30px">
      <div class="md-layout-item md-size-40 md-small-size-100">
        <md-card>
          <md-card-content>
            <div class="header text-center">
              <h3 class="title" style="text-align: center; font-size: x-large; font-weight: bold; margin-top: 2%">
                Acompanhe quanto está rendendo suas indicações!
              </h3>
            </div>
            <div class="detail-indication">
              <div style="display: flex; flex-direction: column">
                <h4>
                  Indicações <img src="../../../assets/images/icons/quality.png" height="10" width="10" />
                  <md-tooltip md-direction="top">O valor pela indicação entrará como cashback no seu saldo após 10 dias de assinatura do indicado.</md-tooltip>
                </h4>
                <span class="text-center title-page">{{ total }}</span>
              </div>
              <div style="display: flex; flex-direction: column">
                <h4>
                  Saldo em cashback <img src="../../../assets/images/icons/refund.png" height="10" width="10" />
                  <md-tooltip md-direction="top"
                    >Após a solicitaçao de resgate, o valor será transferido para a conta vinculada a sua chave Pix em até 24 horas (segunda a
                    sexta).</md-tooltip
                  >
                </h4>
                <span class="text-center" style="font-weight: bold">R$ <span class="title-page">0,00</span></span>
                <md-tooltip md-direction="top">Estamos calculando seu cashback, em breve você verá seu valor atualizado =)</md-tooltip>
              </div>
            </div>
            <div style="display: flex; justify-content: center; margin-top: 10px">
              <span>
                <md-button :disabled="cashback < 50" class="md-primary">Solicitar resgate</md-button>
                <md-tooltip v-if="cashback < 50" md-direction="bottom">Seu saldo ficará disponível para resgate a partir de R$ 50,00.</md-tooltip>
              </span>
            </div>
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item">
        <md-card>
          <md-card-content>
            <div class="md-layout-item">
              <md-table v-model="referral" style="display: inline-block; width: 100%">
                <md-table-toolbar>
                  <h3 class="md-title" style="font-size: x-large; font-weight: bold">Últimos 5 indicados</h3>
                </md-table-toolbar>
                <md-table-row style="text-align: center" slot="md-table-row">
                  Suas indicações aparecerão aqui quando cadadastrarem utilizando seu link</md-table-row
                >
                <md-table-row v-if="referral.length" slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Criado em" data-title="Criado em">
                    <span style="font-weight: bold">{{ item.created_at | DateFormat }}</span>
                  </md-table-cell>
                  <md-table-cell md-label="Nome" data-title="Nome">
                    <span style="font-weight: bold">{{ item.first_name }}</span>
                  </md-table-cell>
                  <md-table-cell md-label="Status" data-title="Status">
                    <span style="font-weight: bold">{{ item.status || 'Inativo' }}</span>
                  </md-table-cell>
                  <md-table-cell md-label="Último Cashback" data-title="Último Cashback">
                    <span style="font-weight: bold">{{ (item.last_cashback * 1) | CurrencyFormat }}</span>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    profile: null,
    urlReferral: null,
    referral: [],
    isReferralLoaded: false,
    defaultPageSize: 5,
    total: 0,
    cashback: 0,
    copied: false,
  }),
  methods: {
    copyLink() {
      // Copy the text inside the text field
      navigator.clipboard.writeText(this.urlReferral)
      this.$store.dispatch('alerts/successBottom', 'Link copiado.')
      this.copied = true
      setTimeout(() => (this.copied = false), 5000)
    },
    async getReferral() {
      if (this.profile == null) {
        this.profile = await this.$store.dispatch('profile/me')
      }
      this.urlReferral = 'https://app.apurebolsa.com.br/cadastrar?referralCode=' + this.profile.referral_code
      var referral = null
      this.params = {
        token: this.$store.getters.token,
        page: 1,
        pageSize: this.defaultPageSize,
        initialDate: this.initialDate,
        finalDate: this.finalDate,
        onlyClosed: this.onlyClosed,
      }
      referral = await this.$store.dispatch('profile/getReferral', this.params)
      referral = await referral.items
      this.total = referral.count
      this.referral = this.referral.concat(referral.items)
      this.isReferralLoaded = true
    },
  },
  beforeMount: function () {
    this.$nextTick(function () {
      this.getReferral()
    })
  },
}
</script>
<style lang="scss">
#card-indication {
  width: 100%;
}

.copy-container {
  display: flex;
  margin-top: -20px;
  padding-bottom: 20px;
  flex-direction: column;
  justify-content: center;
}

.gift-img {
  position: absolute;
  top: -28px;
  right: 48%;
}

#card-indication {
  .md-card img {
    width: 60px !important;
    width: 60px !important;
  }
}

#detail-indication {
  .md-card img {
    width: 22px !important;
    width: 22px !important;
    margin-left: 5px;
  }
}

#refferal {
  margin-top: -40px;
  .md-layout {
    padding: 0 0;
  }
}

@media (max-width: 690px) {
  #refferal {
    .md-layout {
      padding: 0 10px;
    }
  }
  .gift-img {
    right: 42%;
  }
}

.copy-content {
  border: 1px solid rgb(200, 200, 200);
  border-radius: 8px;
  padding: 5px 5px 5px 10px;
  height: 50px;
  display: flex;
  align-items: center;
  width: 60%;
  justify-content: space-between;
}

.detail-indication {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 15px;
}

@media (max-width: 400px) {
  .detail-indication {
    flex-direction: column;
    justify-content: center;
    h4 {
      text-align: center;
    }
  }
}

@media (max-width: 719px) {
  .copy-content {
    width: 100%;
    flex-direction: column;
    height: 100%;
  }
}
</style>
