<template>
  <div>
    <md-field>
      <md-input
        v-model="stockIds"
        id="stockIds" name="stockIds"
        @input="onInput"
        placeholder="Digite para buscar..."
      />
    </md-field>
    <div v-if="selectedResults.length > 0">
      <span v-for="(result, index) in selectedResults" :key="index" @click="removeSelection(index)">
        {{ result.stockId }}<strong>&nbsp;x&nbsp;&nbsp;</strong>
      </span>
    </div>
    <ul v-if="results.length > 0">
      <li v-for="(result, index) in results" :key="index" @click="onSelect(result)">
        {{ result.stockId }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
    authToken: {
      type: String,
      required: true,
    },    
  },
  data() {
    return {
      stockIds: '',
      results: [],
      selectedResults: [],
    };
  },
  methods: {
    onInput() {
      if (this.stockIds.length > 0) {
        const headers = new Headers({
          'accept': 'application/json',
          'Authorization': `Bearer ${this.authToken}`,
        });
        var url = process.env.VUE_APP_API_BASE_URL
        fetch(`${url}${this.apiUrl}?name=${this.stockIds}`, { headers })
          .then((response) => response.json())
          .then((data) => {
            this.results = data; // Ajuste conforme a estrutura da resposta da sua API
          })
          .catch((error) => console.error('Erro:', error));
      } else {
        this.results = [];
      }
    },
    onSelect(result) {
      this.selectedResults.push(result);
      this.$emit('select', result);
      this.stockIds = '';
      this.results = [];
    },
    removeSelection(index) {
      var valor = this.selectedResults.splice(index, 1);
      // Atualizar os valores emitidos após a remoção
      this.$emit('update:select', this.selectedResults.map(r => index));
      console.log(valor[0].stockId);
      this.$emit('remove', valor[0].stockId);
    },        
  },
};
</script>

<style scoped>
/* Adicione estilos conforme necessário */
ul {
  list-style-type: none;
  padding: 0;
}
li {
  cursor: pointer;
}
</style>